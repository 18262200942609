/**
 * Style variables
 */

@font-size-base:          16px;

@custom-blue-light: #F4F7F6;
@custom-blue: #5CBFD6;
@custom-blue-dark: #00517C;

@border-radius-base:        0px;
@border-radius-large:       0px;
@border-radius-small:       0px;

@navbar-height: 70px;

@competition-user-guide-cyrcle-size:    55px;

/**
 * Styles
 */
html {
	overflow-y: scroll;
}

.dark-blue {
    color: @custom-blue-dark;
}

body {
	background: @custom-blue-light;
	width: 	100%;
}

h1, h2, h3, h4, h5, h6 {
	letter-spacing: .01em;
    line-height: 1.4;
}

h1 {
	color: @custom-blue;
	// text-transform: uppercase;
	margin-bottom: .6em;
    font-size: 21px;
}

h2 {
	color: @custom-blue-dark;
}

h3 {
	color: @custom-blue-dark;
}

li, p {
	color: @custom-blue-dark;
	letter-spacing: .04em;
}

#jumbotron-wrapper {

    margin: 0;
    background: none;
    text-align: center;
    img {
        margin: 0 auto;
        max-width: 100%;
    }
}

#grid-wrapper {
	padding: 5px;
	box-sizing: border-box;
	overflow: hidden;
	max-height: 500px;

	#intro-banner-wrapper {
		position: relative;
		top: 100px;

		#intro-banner {

			position: absolute;
			z-index: 10;
            left: 50%;
            -webkit-transform: translate(-50%);
            -moz-transform: translate(-50%);
            -ms-transform: translate(-50%);
            -o-transform: translate(-50%);
            transform: translate(-50%);

			#intro-banner-box {
				background: white;
				padding: 57px;
				opacity: .95;
				margin: 0 auto;
				position: relative;
				// left: 50%;

				img {
					max-width: 100%;
					min-width: 240px;
				}
			}
		}
	}

	#grid {
		width: 100%;
		margin: 0;
	}
	.grid {
		margin: 5px;
	}
}

#navbar {

	background: @custom-blue;
	box-shadow: none;
	border: none;
	margin-bottom: 0;

	.navbar-brand {
	    img {
	    	max-height: 40px;
            height: 40px;
            // width: 76.5px;
            width: 54px;
	    	margin-top: -10px;
	    }
	}

	.navbar-collapse {
		border-color: transparent;
	}

	.navbar-toggle {
		border-color: transparent;

		.icon-bar {
			background-color: @custom-blue-light;
		}
	}

	.navbar-toggle:hover {
		background: darken(@custom-blue, 10);
	}


ul{
	background: @custom-blue;
	li {
		a {
			text-transform: uppercase;
			color: @custom-blue-light;
			letter-spacing: .1em;
			font-weight: 300;
			font-size: .9em;
			background: @custom-blue;
		}
		a:hover {
			background: darken(@custom-blue, 10);
		}
	}
}
	li.active > a {
		background: lighten(@custom-blue, 10);
	}
}

#forkrafttaker-wrapper{
    background: white;
}
#grid-container {
  margin-bottom: 100px;
  color: @custom-blue;
}
#grid-container .hashtag-header {
  text-align: center;
  margin: 100px 0 50px 0;
}
#grid-container .items {
  background: #f4f7f6;
  height: 200px;
  border: 10px solid white;
  box-sizing: border-box;
  margin-bottom: 10px;
}
#infographic-wrapper {
	// background: white;
	padding: 55px 0 60px 0;
	border-bottom: 1px solid @custom-blue-light;

    .infographic-logo{
        height: 185px;
    }

    .infographic-arrow{
        font-size: 33px;
        color: @custom-blue;
        margin-top: 40px;
    }
}

#competition-user-guide {
    .carousel-indicators {
        top: 90px;
        bottom: 0px;

        li{
            display: inline-block;
            width: @competition-user-guide-cyrcle-size;
            height: @competition-user-guide-cyrcle-size;
            margin: 7px;
            text-indent: 0px;
            border: 0px solid #FF0000;
            border-radius: @competition-user-guide-cyrcle-size;
            cursor: pointer;
            background-color: #000 \9;
            background-color: rgba(0, 0, 0, 0);
            // position: absolute;
            // bottom: 90px;

            img{
                display: inline-block;
                width: @competition-user-guide-cyrcle-size;
                height: @competition-user-guide-cyrcle-size;
                margin: 0px;
                border-radius: @competition-user-guide-cyrcle-size;
            }

            .active{
                margin: 7px;
                width: @competition-user-guide-cyrcle-size;
                height: @competition-user-guide-cyrcle-size;
                background-color: #ffffff;
            }
        }
    }
    .item{
        height: 685px;
        padding-top: 215px;

        .content-col{
            height: 200px;
        }

        img{
            max-height: 260px;
        }

        .carousel-caption {
            // position: absolute;
            left: 0%;
            right: 0%;
            bottom: 0px;

            // z-index: 10;
            // padding-top: 20px;
            padding-bottom: 0px;
            // color: #ffffff;
            // text-align: center;
            text-shadow: 0 0px 0px rgba(0, 0, 0, 0.6);
        }

        h3 {
            font-weight: 100;
        }
    }
}

.center{
    text-align: center;
}

#competition-user-guide-mobile {
    text-align: center;
    .step:last-of-type{

        h3{
            margin-bottom: 100px;
        }
    }

    .step-num{
        height: 45px;
        margin: 40px 0 25px 0;
    }

    .step-logo{
        max-height: 115px;
        max-width: 145px;
    }

    .step-text{

        h1{
            font-size: 21px;
        }

        h3{
            font-size: 14px;
        }
    }

    hr{
        margin: 45px 0 20px 0;
        border: 0;
        border-top: 1px solid @custom-blue;
    }
}

#stats-wrapper {
	background: white;
	padding: 90px 0 70px 0;
	text-align: center;
	border-bottom: 1px solid @custom-blue-light;

	h1, h2 {
		color: @custom-blue-dark;
		text-transform: uppercase;

	}

	h1 {
		font-size: 2em;
	}
	h2 {
		font-size: 1.8em;
		font-weight: 300;
	}
}

#projects-wrapper {
	padding: 90px 0;

	h1 {
		text-align: center;
	}

    .content-box {
        background: @custom-blue;

        padding: 20px;
        box-sizing: border-box;
        h3 {
            color: white;
            text-shadow: none;
            font-size: 1.5em;
            font-weight: 300;
            margin: 0;
            text-transform: uppercase;
            margin-bottom: 5px;
        }

        a {
            color: white;
            text-decoration: none;
        }
        strong {
            text-transform: uppercase;
        }

        p {
            margin: 0;
            margin-bottom: 5px;
            font-size: .9em;
            font-weight: 400;
            letter-spacing: 0px;
            color: white;
        }
    }

    .vote-counter-box {
        height: 200px;
    }

    .image-box {
        img {
            max-width: 100%;
        }
        overflow: hidden;

    }
}

.vote-counter {
    background: @custom-blue-light;
    color: white;
    display: inline-block;
    margin: 30px 0;
    text-align: center;

    span {
        width: 40px;
        line-height: 40px;
        height: 44px;
        display: inline-block;
        border: 2px solid white;
        background: @custom-blue;
        font-size: 30px;
        text-align: center;
    }

    span.last {
        background: @custom-blue-dark;
        color: white;
    }
}

#tint-wrapper {
	background: white;
	text-align: center;
	padding-top: 90px;
}

.text-align{
    text-align: center;
}

.v-center>*{
    position: relative;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
}
/**
 * BACKUP FROM OLD SYSTEM...MAY BE REWRITTEN
 */

.vote-box {
    position: relative;
    .vote-position {
        right: 34px;
        top: 285px;
        z-index: 200;
        position: absolute;
    }

    .hashtag {
        margin: 0;
        font-weight: 500;
        font-size: .8em;
    }
    h3 {
        margin: 10px 0;
        font-weight: 300;
        font-size: 1.2em;
    }

    .vote-cta {
        background: white;
        padding-top: 10px;
        position: absolute;
        bottom: 0;
        width: 100%;
        a {
            font-weight: 900;
            font-size: 1em;
            letter-spacing: .05em;
        }
    }

    .vote-counter-box {
        background: @custom-blue;
        text-align: center;
        position: relative;
        h5 {
            color: @custom-blue-light;
            font-weight: 200;
            font-size: 1.5em;
            letter-spacing: .1em;
        }

        .vote-counter {
            background: @custom-blue-light;
            color: @custom-blue;
            display: inline-block;

            span {
                padding: 1px 10px;
                display: inline-block;
                background: @custom-blue-light;
                border: 2px solid @custom-blue;
                font-size: 1.4em;
            }

            span.last {
                background: @brand-warning;
                color: white;
            }
        }
    }
}

.well {
    background: white;
    box-shadow: none;
    border: none;

    .left-col {
        padding-left: 0;
    }
    .right-col {
        padding-right: 0;
    }

    .vote-counter-box {
        background: @custom-blue;
        text-align: center;

        h5 {
            color: @custom-blue-light;
            font-weight: 200;
            font-size: 1.5em;
            letter-spacing: .1em;
        }
        padding: 5px 0 10px 0;
        .vote-countdown {
            color: white;
            font-size: 1.3em;
            font-weight: 200;
        }
        .vote-counter {
            background: @custom-blue-light;
            color: @custom-blue;
            display: inline-block;
            margin-bottom: 10px;
            span {
                padding: 0px 10px;
                display: inline-block;
                border: 2px solid @custom-blue;
                font-size: 2em;
                font-weight: 900;
            }

            span.last {
                background: @brand-warning;
                color: white;
            }
        }
    }
}

.vote-position {
    background: @custom-blue-dark;
    text-align: center;
    height: 50px;
    line-height: 50px;
    color: white;
    font-size: 30px;
    width: 80px;
    position: absolute;
    right: 24px;
    top: 100px;
    z-index: 10;
    box-shadow: -3px 3px 3px rgba(0,81,124,.2);
    .vote-position-inner {
        position: relative;
    }
    .vote-position-arrow {
        border-bottom: 10px solid transparent;
        border-right: 10px solid transparent;
        border-left: 10px solid @custom-blue;
        position: absolute;
        right: -10px;
        bottom: -10px;
        z-index: 100;
    }
}

#project-wrapper {

    color: @custom-blue-dark;

    h2.no-margin {
        margin-top: 0;
    }

    .breadcrumb {
        background: none!important;
        margin: 0;
        padding-left: 0;
        color: @custom-blue;
        li.active {
            color: @gray-light;
        }
        a {
            color: @custom-blue-dark;
        }
    }

    h1 {
        color: @custom-blue;
        // text-transform: uppercase;
        margin-bottom: .6em;
        font-size: 2em;
    }

    h2 {
        color: @custom-blue;
        font-size: 1.5em;
        font-weight: 300;
    }

    h3 {
        color: @custom-blue-dark;
    }

    .image-box {
        overflow: hidden;

        img {
            min-width: 100%;
        }
    }

    #hashtag {
        background: white;
        padding: 20px;
        margin-bottom: 10px;

        h2 {
            color: @custom-blue-dark;
            font-size: 1.2em;
            font-weight: 500;
            margin: 0;
            padding: 0;
        }
    }

    .vote-counter-box {
        height: 250px;
    }
}

#form-container {
    h1, h2, h3, h4, h5, h6 {
        text-align: left;
        margin: 2em 0;
        // font-weight: 300;

    }
    ::-webkit-input-placeholder {
        color: @custom-blue-dark!important;
    }

    label {
        color: @custom-blue;
    }

    legend {
        color: @custom-blue-dark;
    }
}

hr {
    border-color: @custom-blue;
    border-width: 2px;
    margin-top: 60px;
    margin-bottom: 60px;
}

.spinner {
    position: relative;
    width: 0;
    display: inline-block;
}

#signup-form {
    margin-bottom: 50px;
    .fieldset-heading .question-box {
        text-align: right;
    }

    .check {
        margin-right: 10px;
        color: @custom-blue;
    }

    .check-error {
        margin-right: 10px;
        color: @brand-danger;
    }

    .help-block {

    }

    .has-error {
        input, textarea {
            // background: @brand-danger;
            border-color: darken(@btn-danger-bg, 5%);
        }

        ::-webkit-input-placeholder {
            color: @gray-light!important;
        }
    }

    .progress {
        height: 3px;
        box-shadow: none;
        border: none;
        background: @gray-lighter;
        .progress-bar {
            box-shadow: none;
            border: none;
        }
    }

    .well {
        border: 1px solid @gray-lighter;
    }

    .well.disabled {

    }
}

.popover {
    background: @custom-blue;
    border: none;
    color: @custom-blue-light;
    letter-spacing: .5px;
    ul {
        font-size: 12px;
        padding-left: 20px;
        li {
            margin-bottom: 5torbpx;
        }
    }
    .popover-title {
        text-align: left;
        background: none;
        border: none;
        color: @custom-blue-light;
        display: none;
    }

    h3 {
        font-size: 17px;
    }
}

.popover.left {
    margin-left: -15px;
}


.popover.left .arrow:after {
    border-left-color: @custom-blue;
}

.popover.left .arrow {
    border-left-color: rgba(255,255,255,0);
}

legend {
    border: none;
}


a.question-mark {
    background: @custom-blue;
    height: 40px;
    width: 40px;
    line-height: 40px;
    margin-top: -5px;
    display: inline-block;
    text-align: center;
    color: white;
    font-size: 30px;
    border-radius: 30px;
    font-weight: 500;
    text-decoration: none;
    border: 1px solid @custom-blue;
}

a.question-mark:hover {
    background: white;
    color: @custom-blue;
}

.background-blue-light {
    background: @custom-blue-light;
}

.downloads {

    .col-sm-3 {
        text-align: center;
        margin-bottom: 20px;
    }

    a {
        text-decoration: none;
        color: @custom-blue;
        margin-bottom: 20px;
        img {
            clear: both;
            margin-bottom: 10px;
        }
        strong {
            clear: both;
            width: 100%;
            float: left;
        }
    }
    a:hover {
        color: @custom-blue-dark;
    }
    a:active {
        background: none!important;
    }
}

.media-stream-wrapper{
    width: 100%;
    height: 100vh;
	text-align: center;
	.hashtag-header{
		padding-top: 90px;
		padding-bottom: 45px;
		font-weight: bold;
	}
    .media-stream{
        width: 100%;
        height: 80%;
		border-width: 0;
    }
}

/**
 * MEDIA QUERIES
 */


/* Small devices (tablets, 768px and up) */
@media (min-width: @screen-sm-min) {
	#grid-wrapper {
		max-height: 805px;

		#intro-banner-wrapper {
			top: 160px;
		}
	}

	.vote-counter {

	    span {
	        width: 50px;
	        line-height: 50px;
	        height: 54px;
	        display: inline-block;
	        border: 2px solid white;
	        background: @custom-blue;
	        font-size: 30px;
	        text-align: center;
	    }
	}
}

@media (min-width: @screen-sm-max) {
    .cta-left {
        padding-right: 7.5px;
    }
    .cta-right {
        padding-left: 7.5px;
    }
}

@media (min-width: @screen-xs-min) and (max-width: @screen-sm-min) {
    .cta-left {
        margin-bottom: 10px;
    }

}

@media (max-width: @screen-md-max) {
    .vote-counter-box {
        height: 200px;
    }
}

/* Medium devices (desktops, 992px and up) */
@media (min-width: @screen-md-min) {
    .text-align{
        text-align: left;
    }

    h1{
        font-size: 37px;
    }

    .vote-counter-box {
        height: 200px;
    }

    #grid-wrapper {

        #intro-banner-wrapper {

            #intro-banner {

                #intro-banner-box {
                    img {
                        min-width: 356px;
                    }
                }
            }
        }
    }

}

@media (min-width: @screen-md-max) {

    #project-wrapper {
        .vote-counter-box.fixed-height {
            height: 315px;
            padding-top: 70px;
        }
    }
}

/* Large devices (large desktops, 1200px and up) */
@media (min-width: @screen-lg-min) {
    #grid-wrapper {

        #intro-banner-wrapper {

            #intro-banner {

                #intro-banner-box {
                    img {
                        min-width: 456px;
                    }
                }
            }
        }
    }
}
